import { Component, OnInit, VERSION } from '@angular/core';
import { NgForm, FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PasswordService } from '@app/common/services/password.service';
import { HeaderService } from '../common/services/header.service';


@Component({
  selector: 'app-login',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss']
})
export class PasswordComponent implements OnInit {

  registerForm: FormGroup;
  contentCmsIntro: any;
  contentCmsTips: any;
  logo: string;
  public dataForm = {
    inputCompany: ''
  };
  public company: any[] = [];
  public version = VERSION.full;
  public reactiveForm: FormGroup = new FormGroup({
    recaptchaReactive: new FormControl(null, Validators.required)
  });

  constructor(private router: Router, private formBuilder: FormBuilder, private toastr: ToastrService, private headerService: HeaderService,
    private passwordService: PasswordService) {
    this.company = JSON.parse(localStorage.getItem('companies'));
    this.headerService.getInitData().subscribe((initParams) => {
      this.company = initParams;
      console.log(this.company[1]);
    });
  }


  ngOnInit() {
    this.getCmsIntro();
    this.getCmsTips();
    this.logo = localStorage.getItem('logo');
    // this.company = JSON.parse(localStorage.getItem('companies'));
    this.dataForm.inputCompany = Object.keys(this.company)[0];
  }

  recoveryPassword(form: NgForm) {
    const userEmail = form.value.inputEmail;
    const capcha = form.value.capcha;
    const compa = Object.keys(this.company)[0];

    console.log(compa);

    this.registerForm = this.formBuilder.group({
      email: [userEmail, [Validators.required, Validators.email]],
      capcha: [capcha, [Validators.required]]
    });

    if (this.registerForm.invalid) {
      if (this.registerForm.controls.email.errors) {
        if (this.registerForm.controls.email.errors.email) {
          this.showError('Lo sentimos! El correo tiene un formato incorrecto');
          return;
        }
        if (this.registerForm.controls.email.errors.required) {
          this.showError('Lo sentimos! Debe ingresar un correo válido');
          return;
        }
      }
      if (this.registerForm.controls.capcha.errors) {
        if (this.registerForm.controls.capcha.errors.required) {
          this.showError('Debe seleccionar el captcha para restablecer la contraseña');
          return;
        }
      }
    }

    this.passwordService.recoveryPassword(userEmail, compa)
      .subscribe((passResponse: any) => {
        console.log(passResponse)
        if (passResponse.error) {
          this.showError(passResponse.message);
        }
        // if (passResponse.ExisteUser) {
        //   this.showError(passResponse.message);
        // }
        if (!passResponse.ExisteUser) {
          this.router.navigate(['/login']);
          this.showError(passResponse.message);
        }
        if (passResponse.ExisteUser == true) {
          this.router.navigate(['/login']);
          this.showSucces('Se ha enviado una nueva clave de acceso a su correo');
        }


      });
  }


  showError(message: string) {
    this.toastr.error(message);
  }

  showSucces(message: string) {
    this.toastr.success(message);
  }

  getCmsIntro() {
    const ubucation = 'RESTABLECER_PASSWORD_INTRO_AGP';
    this.passwordService.contentIntro(ubucation)
      .subscribe((cmsResponseIntro: any) => {
        return this.contentCmsIntro = cmsResponseIntro;
      });
  }

  getCmsTips() {
    const ubucation = 'RESTABLECER_PASSWORD_TIP_AGP';
    this.passwordService.contentTips(ubucation)
      .subscribe((cmsResponseTips: any) => {
        return this.contentCmsTips = cmsResponseTips;
      });
  }
}
